import React from "react";
import {
  Modal,
  ModalContent,
} from "@nextui-org/modal";
import BackgroundImage from "./background.png";
import ContactForm from "../ContactForm";
import Image from "next/image";

const ContactFormModal = ({ isOpen, open, close }: any) => {
  return (
    <>
      <Modal placement="center" isOpen={isOpen} onOpenChange={open} onClose={close}>
        <ModalContent className="relative">
          <div className="z-11 size-full  md:min-h-[480px] rounded-2xl">
            <div className="p-5">
              <div className=" w-full overflow-hidden min-h-[180px] mt-3">
                <div className="relative z-10">
                  <div className="pb-5 md:pb-0 h-1 text-[adahubPrimary] pt-2 px-4 flex flex-col items-center">
                    <h1 className="font-bold text-3xl text-adahubSecondary ">
                      LIÊN HỆ TƯ VẤN
                    </h1>
                  </div>
                  <div className="overflow-visible pt-5">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Image
            placeholder="blur"
            src={BackgroundImage}
            alt="Background"
            className="z-1 object-cover w-full h-full absolute"
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContactFormModal;
