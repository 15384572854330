import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { ServerFormError } from "@/services/types";
import { cookieParamsLandingPage } from "@/constants/cookie";
import { useLandingPageAppState } from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/Providers";

export interface CreateLeadParams {
  phone_number: string;
  name: string;
}

export interface CreateLeadResponse {}

function useCreateLead(call_to_action: "FORM" | "DEMO") {
  const { axiosInstance } = useLandingPageAppState();

  return useMutation<
    AxiosResponse<CreateLeadResponse>,
    ServerFormError,
    CreateLeadParams
  >({
    mutationFn: (formData) => {
      const dataParams = {
        ...formData,
        call_to_action: call_to_action,
        ...cookieParamsLandingPage(),
      };
      return axiosInstance.post("/lead", dataParams);
    },
  });
}

export default useCreateLead;
