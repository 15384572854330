const ContentContainer = ({
  children,
  noPx,
  className,
}: {
  children: JSX.Element;
  noPx?: boolean;
  className?: string;
}) => {
  return (
    <div className={`flex flex-row items-center justify-center ${className}`}>
      <div className={`w-full max-w-screen-lg ${noPx ? "" : "px-4"}`}>
        {children}
      </div>
    </div>
  );
};

export default ContentContainer;
