const LandingPageRoutes = {
  home: "/home",
  products: {
    messages: "/home/products/messages",
    callbot: "/home/products/callbot",
    crm: "/home/products/crm",
    ulead: "/home/products/ulead",
    omnichannel: "/home/products/omnichannel",
    uflow: "/home/products/uflow",
    callCenter: "/home/products/call-center",
    telesales: "/home/products/telesales",
    emailMarketing: "/home/products/email-marketing",
  },
  solutions: {
    findLeads: "/home/solutions/find-leads",
    customerSupport: "/home/solutions/customer-support",
    filterCustomers: "/home/solutions/filter-customers",
    leadNurturing: "/home/solutions/lead-nurturing",
    salesManagement: "/home/solutions/sales-management",
    telesales: "/home/solutions/telesales",
  },
  landingPage: {
    callbot: "/p/callbot",
    crm: "/p/crm",
  },
  thankYou: "/thank-you",
  pricing: "/home/pricing",
  contactUs: "/home/contact-us",
};

export default LandingPageRoutes;
