import ContactForm from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/ContactForm";
import BackgroundImage from "./background.png";
import Image from "next/image";

const ContactUsForm = () => {
  return (
    <section id="contact">
      <div className="h-[330px]" />

      <div className="flex flex-column align-items-center justify-center">
        <div className="px-4 max-w-[1200px] w-full min-h-[586px] position-absolute">
          <div
            className="relative w-full overflow-hidden min-h-[586px] rounded-2xl mt-3"
            style={{ zIndex: 7 }}>
            <Image
              placeholder="blur"
              src={BackgroundImage}
              alt="Background"
              className="-z-10 object-fit-cover w-full h-full position-absolute"
            />
            <div className="relative z-10 flex items-center justify-center h-full full-w">
              <div className="w-full h-full">
                <div className="p-4">
                  <div className="flex flex-row flex-wrap justify-between">
                    <div className="col-12 col-md-5 hidden md:block pl-6">
                      <h4 className="font-bold text-2xl text-adahubSecondary mt-16">
                        Giải phóng tiềm năng đội ngũ kinh doanh của bạn
                      </h4>
                      <ul className="space-y-4 mt-8">
                        <li className="flex items-center">
                          <span className="text-green-500 mr-3">✔</span>
                          <span>Dùng thử miễn phí 7 ngày</span>
                        </li>
                        <li className="flex items-center">
                          <span className="text-green-500 mr-3">✔</span>
                          <span>Miễn phí thiết lập ban đầu</span>
                        </li>
                        <li className="flex items-center">
                          <span className="text-green-500 mr-3">✔</span>
                          <span>Tư vấn miễn phí bởi đội ngũ chuyên gia</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 col-md-6 py-9 px-0 lg:px-9">
                      <ContactForm check_mobile={false} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsForm;
